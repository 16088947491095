export const mineConfig = [
  {
    field: 'oppoLevel',
    title: '商机等级',
    type: 'simple',
    defaultVal: '',
    componentName: 'TagSelect',
    options: [],
    getOptions(dict) {
      return dict.oppoLevel || []
    }
  },
  {
    field: 'trackReliable',
    title: '进程推进',
    type: 'number',
    defaultVal: '',
    componentName: 'GrooveSlider',
    options: [],
    getOptions(dict) {
      const options = JSON.parse(JSON.stringify(dict.reliable || []))
      const reg = /\d+\%/
      options.forEach(option => {
        const list = option.text.match(reg)
        option.percentage = list && list[0]
      })
      return options
    },
    getValue(options, val) {
      let value = ''
      options.forEach(option => {
        if (val === option.percentage) {
          value = option.value
        }
      })
      return value
    },
    step: 10,
    titleBock: {
      numPre: '靠谱度',
      unit: '%',
      placeholder: '请选择靠谱度'
    }
  },
]

export const branchConfig = [
  {
    field: 'oppoLevel',
    title: '商机等级',
    type: 'simple',
    defaultVal: '',
    componentName: 'TagSelect',
    options: [],
    getOptions(dict) {
      return dict.oppoLevel || []
    }
  },
  {
    field: 'trackReliable',
    title: '进程推进',
    type: 'number',
    defaultVal: '',
    componentName: 'GrooveSlider',
    options: [],
    getOptions(dict) {
      const options = JSON.parse(JSON.stringify(dict.reliable || []))
      const reg = /\d+\%/
      options.forEach(option => {
        const list = option.text.match(reg)
        option.percentage = list && list[0]
      })
      return options
    },
    getValue(options, val) {
      let value = ''
      options.forEach(option => {
        if (val === option.percentage) {
          value = option.value
        }
      })
      return value
    },
    step: 10,
    titleBock: {
      numPre: '靠谱度',
      unit: '%',
      placeholder: '请选择靠谱度'
    }
  },
  {
    field: 'sellerUserId',
    title: '销售',
    type: 'string',
    defaultVal: '',
    componentName: 'GoTree',
    treeType: 'sellerUserId'
  },
  {
    field: 'orgId',
    title: '所属部门',
    type: 'string',
    defaultVal: '',
    componentName: 'GoTree',
    treeType: 'orgId'
  },
  {
    field: 'branchOrgId',
    title: '分公司',
    type: 'string',
    defaultVal: '',
    componentName: 'OldCompBranch2'
  },
]

